// vendors
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Parallax from '../../components/Parallax/Parallax';
import InView from '../../components/InView/InVew';

const L1 = 0.043014853;
const L2 = 0.063164248;
const L3 = 0.0927522;
const L4 = 0.1362;
const L5 = 0.2;

function HomePageAbout({ historyText, whatToDoText, storeText }) {
  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    const handleResize = () => {
      const value = window.matchMedia('(max-width: 1024px)').matches;

      if (value !== isMobile) setIsMobile(value);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className="container grid grid-cols-2 gap-x-5 gap-y-4 mx-auto lg:grid-cols-6 lg:gap-0">
      <div className="col-span-2 col-start-1 row-start-3 my-14 text-center lg:col-span-3 lg:row-start-1 lg:justify-self-start lg:self-center lg:text-left">
        {historyText}
      </div>

      <Parallax
        disable={isMobile}
        delta={L3}
        className="col-span-1 col-start-1 row-span-1 row-start-1 lg:col-span-2 lg:col-start-5"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-01.jpg"
          width={554}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 554px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L2}
        className="col-span-2 col-start-1 row-span-1 row-start-2 lg:col-span-3 lg:row-span-2"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-02.jpg"
          width={906}
          loading="lazy"
          sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L5}
        className="relative col-span-1 col-start-2 row-span-1 row-start-4 lg:-top-1/3 lg:col-span-2 lg:col-start-3 lg:place-self-start lg:w-[17vw] lg:min-w-[10rem] lg:max-w-[20rem]"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-03.jpg"
          width={448}
          loading="lazy"
          quality={90}
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 17vw, 328px"
          className="lg:left-1/3"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L3}
        className="col-start-1 col-end-2 row-start-4 row-end-6 lg:col-span-2 lg:col-start-5 lg:row-start-3 lg:row-end-6"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-04.jpg"
          width={554}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 554px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        className="col-span-1 col-start-2 row-start-7 lg:col-span-2 lg:col-start-1 lg:row-start-5 lg:row-end-7 lg:self-end"
        delta={L3}
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-05.jpg"
          width={554}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 554px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L5}
        className="col-span-1 row-span-2 lg:col-span-2 lg:row-span-1 lg:row-start-7 lg:self-end row-start-9"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-06.jpg"
          width={448}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 17vw, 320px"
          quality={90}
          className="lg:w-[17vw] lg:max-w-xs"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L2}
        className="col-span-2 row-start-6 lg:col-span-3 lg:col-start-4 lg:row-start-7"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-07.jpg"
          width={906}
          loading="lazy"
          sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        />
      </Parallax>

      <div className="col-span-full row-start-[8] justify-self-center my-14 text-center lg:col-span-3 lg:justify-self-start lg:mt-80 lg:text-left">
        {whatToDoText}
      </div>

      <Parallax
        disable={isMobile}
        className="col-span-1 col-start-2 row-span-3 row-start-[10] lg:col-span-1 lg:col-start-5 lg:row-span-1 lg:row-start-[8] lg:mt-80"
        delta={L4}
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-08.jpg"
          width={448}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 14vw, 277px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L3}
        className="col-span-1 col-start-1 row-span-1 row-start-[11] lg:col-span-2 lg:row-span-2 lg:row-start-[9]"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-09.jpg"
          width={556}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 556px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L2}
        className="col-span-2 col-start-1 row-span-1 row-start-[13] lg:col-span-3 lg:col-start-4 lg:row-span-3 lg:row-start-[10]"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-10.jpg"
          width={906}
          loading="lazy"
          sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        className="col-span-2 col-start-1 row-span-1 row-start-[15] lg:col-span-4 lg:col-start-2 lg:row-span-1 lg:row-start-[13] lg:mt-56"
        delta={L1}
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-11.jpg"
          width={1110}
          loading="lazy"
          sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 58vw, 1110px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L5}
        className="relative col-span-1 col-start-2 row-span-1 row-start-[14] lg:-top-1/3 lg:col-span-2 lg:col-start-5 lg:row-span-1 lg:row-start-[14] lg:justify-self-end"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-12.jpg"
          width={448}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 22vw, 416px"
          className="lg:w-[22vw] lg:max-w-[26rem]"
        />
      </Parallax>

      <div className="col-span-full row-span-1 row-start-[16] py-20 font-title text-center lg:row-start-[15]">
        <motion.p
          className="max-w-prose fluid-text-[1.25/3]"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 1 }}
        >
          <FormattedMessage defaultMessage="Vue sur un des plus beaux couchers de soleil au monde" />
        </motion.p>
      </div>

      <Parallax
        disable={isMobile}
        className="col-span-1 col-start-1 row-span-1 row-start-[17] lg:col-span-2 lg:col-start-5 lg:row-span-1 lg:row-start-[16]"
        delta={L3}
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-13.jpg"
          width={556}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 556px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        className="col-span-2 col-start-1 row-span-1 row-start-[19] lg:col-span-3 lg:col-start-1 lg:row-span-1 lg:row-start-[17]"
        delta={L2}
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-14.jpg"
          width={906}
          loading="lazy"
          sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L5}
        className="relative col-span-1 col-start-2 row-span-2 row-start-[17] lg:top-1/2 lg:col-span-1 lg:col-start-4 lg:row-span-1 lg:row-start-[17] lg:self-end"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-15.jpg"
          width={448}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 14vw, 277px"
          className="relative lg:right-1/2"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        className="col-span-1 col-start-2 row-span-1 row-start-[20] lg:col-span-2 lg:col-start-5 lg:row-span-1 lg:row-start-[18] lg:mb-80"
        delta={L3}
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-16.jpg"
          width={556}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 556px"
        />
      </Parallax>

      <Parallax
        disable={isMobile}
        delta={L3}
        className="col-span-1 col-start-1 row-span-1 row-start-[20] self-end lg:col-span-2 lg:col-start-1 lg:row-span-1 lg:row-start-[18] lg:mb-80"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-17.jpg"
          width={556}
          loading="lazy"
          sizes="(max-width: 1023px) 44vw,(max-width: 1920px) 29vw, 556px"
        />
      </Parallax>

      <parallax
        delta={L2}
        className="col-span-2 col-start-1 row-span-1 row-start-[21] lg:col-span-3 lg:col-start-4 lg:row-span-1 lg:row-start-[19]"
      >
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-19.jpg"
          width={906}
          loading="lazy"
          sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        />
      </parallax>

      <Parallax
        disable={isMobile}
        delta={L3}
        className="relative col-span-2 col-start-1 row-span-1 row-start-[22] self-center p-8 bg-paleOrange-50 lg:col-span-4 lg:col-start-1 lg:row-span-1 lg:row-start-[19]"
      >
        <InView>{storeText}</InView>
      </Parallax>
    </section>
  );
}

HomePageAbout.propTypes = {
  storeText: PropTypes.string.isRequired,
  historyText: PropTypes.string.isRequired,
  whatToDoText: PropTypes.string.isRequired,
};

export default HomePageAbout;
