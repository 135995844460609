// vendors
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useIntl } from 'react-intl';
import { m as motion, useAnimation } from 'framer-motion';

function ScrollingText() {
  const textContainerRef = React.useRef(null);
  const slideRef = React.useRef(null);
  const intl = useIntl();
  const controls = useAnimation();

  React.useEffect(() => {
    const handleItersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          controls.start(() => ({
            x: [1, -1 * slideRef.current.scrollWidth],
            transition: {
              repeat: Infinity,
              repeatType: 'loop',
              duration: slideRef.current.scrollWidth * 0.0075,
              ease: 'linear',
            },
          }));
        } else {
          controls.stop();
        }
      });
    };

    const observer = new IntersectionObserver(handleItersection);

    observer.observe(textContainerRef.current);

    return () => observer.disconnect();
  }, [controls, slideRef, textContainerRef]);

  const text = intl.formatMessage({
    defaultMessage: 'Installez-vous face au fleuve et relaxez…',
  });

  return (
    <div className="relative">
      <div className="container mx-auto">
        <StaticImage
          src="../../images/auberge/Auberge-sur-mer-20.jpg"
          role="presentation"
        />
      </div>

      <motion.div
        className="overflow-hidden absolute top-1/2 w-screen"
        ref={textContainerRef}
        whileInView={{ y: '-50%', opacity: 1 }}
        initial={{ y: 0, opacity: 0 }}
        viewport={{ once: true }}
      >
        <motion.div className="flex w-max" animate={controls}>
          <p
            ref={slideRef}
            className="pr-4 font-title text-paleOrange-600 fluid-text-[3/8]"
            dangerouslySetInnerHTML={{ __html: text }}
          />

          <p
            className="pr-4 font-title text-paleOrange-600 fluid-text-[3/8]"
            dangerouslySetInnerHTML={{ __html: text }}
            role="presentation"
          />

          <p
            className="font-title text-paleOrange-600 fluid-text-[3/8]"
            dangerouslySetInnerHTML={{ __html: text }}
            role="presentation"
          />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default ScrollingText;
